import React from 'react';
import cofreImg from './images/cofre100.png';
import rewardBox from './components/rewardBox';
import biruxBronce112 from './images/biruxBronce112.png';
const Rewards = () => {
  const [searchReward, setReward] = React.useState('');
  const [rewards, setRewards] = React.useState(['']);

  React.useEffect(()=> {
    getRewards();
  }, [searchReward])

  const getRewards = async () => {
    let rewardsList = await fetch('/api/rewards?search=' + searchReward,{ method:'POST'});
    let rewardsListDef = await rewardsList.json()
    setRewards(rewardsListDef);
   };

   const handleSearch = (event) => {
      setReward(event.target.value);
   }

  return (
    <div className="topCommands commandsBox page">
      <div className='titlePage'><img src={cofreImg} alt="Recompensas"/><h1 className='heroes'>Recompensas</h1></div>
      <input className="userSearch" placeholder="Busca una recompensa" type="text" onChange={handleSearch}></input>
      <div className="infoBox">
            {rewards.map(reward => (
                <div className='zoom'>
                    <img className='rewardImage' src={'/images/rewards/' + reward.image}></img>
                    <div className='heroes'>
                        <h3 className='name'>{reward.name}</h3>
                        <div className='description'>{reward.description}</div>
                        <div className='commandRow'>
                          {reward.text != 0 ?                             
                            <div className='commandRow'>
                              <div>Texto: {reward.text}</div>
                              <div>Ejemplo: {reward.example}</div>
                            </div> : ''}
                            <div className='commandRow'>
                                <div>Precio: {reward.price} <img src={biruxBronce112}></img></div>
                                <div>Enfriamiento: {reward.cooldown}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Rewards