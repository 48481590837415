import React from 'react';
import biruxBronce112 from './../images/biruxBronce112.png';

const EconomyBox = ({birux}) => {
    return (
        <div className="economyBox">
            <div className='economyMenu'>
                <img src={biruxBronce112}></img>
                <span className='heroes'>{birux}</span></div>
        </div>
    )
}

export default EconomyBox;